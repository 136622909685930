.unknown {
  color: #888;
}

.online {
  color: #0f0;
}

.offline {
  color: #f00;
}
