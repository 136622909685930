$margin: 5px;

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h1 {
      margin: 0 0 $margin;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
    }

    input {
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0 $margin;
      margin: $margin 0;
    }

    button {
      height: 40px;
      border: 0;
      border-radius: 4px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      margin: $margin 0;

      &:hover {
        opacity: 0.8;
      }

      &.login-button {
        background-color: #7159c1;
      }

      &.signout-button {
        background-color: #f44336;
      }
    }

    .error {
      color: #f44336;
      font-size: 14px;
      font-weight: bold;
      margin: $margin 0;
    }
  }
}
